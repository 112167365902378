<template>
    <div class="finilize-shift" v-if="!loading">
        <div class="title">
            <p>סגירת משמרת</p>
        </div>
        <div class="shift-general-data">
            <div class="shift-data" style="color:var(--yellow);">
                <p style="margin-left: 10px;">קוד משמרת פנימי: </p>
                <p>{{shift.uid}}</p>
            </div>
            <div class="shift-data">
                <p style="margin-left: 10px;">תחילת משמרת: </p>
                <p>{{shift.start_date.toDate().toLocaleString('he')}}</p>
            </div>
            <div class="shift-data">
                <p style="margin-left: 10px;">סיום משמרת: </p>
                <p>{{shift.end_date.toDate().toLocaleString('he')}}</p>
            </div>
            <div class="shift-data">
                <p style="margin-left: 10px;">אורך בשעות ודקות: </p>
                <p>{{shift.hours +':'+ shift.minutes}}</p>
            </div>
            <div class="shift-data">
                <p style="margin-left: 10px;">סה"כ עובדים במשמרת: </p>
                <p>{{shift.workers.length}}</p>
            </div>
        </div>
        <div class="workers-summery">
            <template v-for="worker in shift.workers" :key="worker.id">
                <div class="worker-line">
                    <div class="worker-data">
                        <div class="add-note">הוסף הערה</div>
                        <span class="edit_worker">ערוך שעות</span>
                        <p style="color:var(--yellow); font-size: 20px;">{{worker.name}}</p>
                        <p>1. משמרת בסיס</p>
                        <p>שעת התחלה: {{worker.shift.start_time.toLocaleString('he')}}</p>
                        <p>שעת סיום: {{worker.shift.end_time.toLocaleString('he')}}</p>
                        <p class="total_time">סה"כ: {{get_shift_time(worker)}}</p>

                        <div class="divider" style="width:300px; border: 1px solid grey; border-radius: 5px;" v-if="worker.service.start_time"></div>
                        <p v-if="worker.service.start_time">2. שירות</p>
                        <p v-if="worker.service.start_time">שעת התחלה: {{worker.service.start_time.toLocaleString('he')}}</p>
                        <p v-if="worker.service.end_time">שעת סיום: {{worker.service.end_time.toLocaleString('he')}}</p>
                        <p class="total_time" v-if="worker.service.start_time">סה"כ: {{get_service_time(worker)}}</p>
                    </div>
                </div>
            </template>
        </div>
        <div class="money-data">
            <p class="money-title">כספים</p>
            <div class="flex-line">
                <p>תאריך משמרת: </p>
                <p>{{shift.start_date.toDate().toLocaleString()}}</p>
            </div>
            <div class="flex-line">
                <p>הכנס מספר Z: </p>
                <el-input placeholder="מספר Z" v-model="shift.z_number" style="width:100px; margin-right: 10px;"/>
            </div>
            <div class="flex-line">
                <p>סה"כ מזומן: </p>
                <el-input placeholder="מזומן" v-model="shift.total_cash" style="width:100px; margin-right: 10px;"/>
            </div>
            <div class="flex-line">
                <p>סה"כ אשראי: </p>
                <el-input placeholder="אשראי" v-model="shift.total_credit" style="width:100px; margin-right: 10px;"/>
            </div>
            <div class="flex-line">
                <p>סה"כ ביטולים: </p>
                <el-input placeholder="ביטולים" v-model="shift.total_cancels" style="width:100px; margin-right: 10px;"/>
            </div>
            <div class="flex-line">
                <p>סה"כ השלמת טיפים: </p>
                <el-input placeholder="השלמה" v-model="shift.total_tip_added" style="width:100px; margin-right: 10px;"/>
            </div>
        </div>
        <div class="tip-data">
            <p class="money-title">טיפים</p>
            <div class="flex-line" style="color:var(--yellow);">
                <p>סה"כ שעות שירות: </p>
                <p>{{total_service_hours.hours}} שעות ו {{total_service_hours.minutes}} דקות</p>
            </div>
            <div class="flex-line">
                <p>סה"כ טיפ לחלוקה: </p>
                <el-input placeholder="טיפ" v-model="shift.total_tip" style="width:100px; margin-right: 10px;"/>
            </div>
            <div class="flex-line" style="color:var(--success);">
                <p>טיפ לשעה: </p>
                <p v-if="tip_per_hour">{{tip_per_hour.toFixed(2)}} ש"ח</p>
            </div>
            <p class="money-title">חלוקת טיפים</p>
            <div class="tip-destribution">
                <template v-for="worker in shift.workers" :key="worker.id">
                    <div class="flex-line" v-if="worker.service.start_time">
                        <p>{{worker.name}}</p>
                        <p v-if="worker.total_tip">{{worker.total_tip.toFixed(1)}} ש"ח</p>
                    </div>
                </template>
            </div>
        </div>
        <el-button type="success" style="margin: 5px 0 5px 0;width:280px;">סיים ושמור</el-button>
    </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { onMounted, watch } from '@vue/runtime-core';
import { projectFirestore } from '../../firebase/config';
import { get_date_diff } from '../../Methods/Helpers';
import store from '../../store';
import { slide_pop_error } from '../../Methods/Msgs';
import router from '../../router';
export default {
setup(){
    const shift = ref(null);
    const loading = ref(true);

    const get_data = async () =>{
        await projectFirestore.collection('Shifts').orderBy('end_date', 'desc').limit(1).get()
        .then(docs => {
            if(!docs.empty){
                shift.value = docs.docs[0].data()
                let timing = get_date_diff(shift.value.start_date.toDate(), shift.value.end_date.toDate())
                shift.value.hours = timing.hours
                shift.value.minutes = timing.minutes
            }else{
                slide_pop_error("אין משמרות בבסיס הנתונים")
                router.push('/work')
                return
            }
        })
        for(let i = 0; i< shift.value.workers.length; i++){
            let worker = shift.value.workers[i]
            worker.shift.start_time = worker.shift.start_time.toDate();
            worker.shift.end_time = worker.shift.end_time.toDate();
            worker.shift.total_hours = get_date_diff(worker.shift.start_time, worker.shift.end_time).hours
            worker.shift.total_minutes = get_date_diff(worker.shift.start_time, worker.shift.end_time).minutes
            if(worker.service.start_time){
                worker.service.start_time = worker.service.start_time.toDate();
            }
            if(worker.service.end_time){
                worker.service.end_time = worker.service.end_time.toDate();
                worker.service.total_hours = get_date_diff(worker.service.start_time, worker.service.end_time).hours
                worker.service.total_minutes = get_date_diff(worker.service.start_time, worker.service.end_time).minutes
            }

            
        }
        loading.value = false;
        store.state.loader = false;
    }

    const get_shift_time = (worker) => {
        return worker.shift.total_hours + ' שעות ו ' + worker.shift.total_minutes + ' דקות '
    }
    const get_service_time = (worker) => {
        return worker.service.total_hours + ' שעות ו ' + worker.service.total_minutes + ' דקות '
    }

    const total_service_hours = ref(computed(() => {
        if(shift.value){
            let count=  0;
            let minutes = 0;
            shift.value.workers.forEach(worker => {
                count += worker.service.total_hours
                minutes += worker.service.total_minutes
            })
            return {hours: count, minutes: minutes}
        }else{
            return null
        }
    }))

    const tip_per_hour = ref(computed(() => {
        if(total_service_hours.value && shift.value.total_tip){
            let total_minutes = total_service_hours.value.hours * 60 + total_service_hours.value.minutes
            let per_hour = Number(shift.value.total_tip) /(total_minutes / 60) 
            shift.value.workers.forEach(worker => {
                if(worker.service.start_time && worker.service.end_time){
                    worker.tip_per_hour = per_hour
                    worker.total_tip = (Number(shift.value.total_tip) / total_minutes) * (worker.service.total_hours*60 + worker.service.total_minutes)
                }
            })
            return per_hour
        }else{
            return null
        }
    }))

    const update_workers_shift_data = async () => {
    for(let i =0; i< shift.value.workers.length; i++){
        let worker = shift.value.workers[i]
        await projectFirestore.collection('Workers').doc(worker.id).collection('Shifts').doc(new Date().toLocaleDateString('he')).set({
            shift_uid: shift.uid,
            service_start: worker.service.start_time,
            service_end: worker.service.end_time,
            service_minutes: worker.service.total_minutes,
            service_hours: worker.service.total_hours,
            shift_start: worker.shift.start_time,
            shift_end: worker.shift.end_time,
            shift_minutes: worker.shift.total_minutes,
            shift_hours: worker.shift.total_hours
        })
    }
    }
    
    onMounted(async () => {
        get_data();
    })

    return{
        shift, get_date_diff, loading, get_shift_time, get_service_time, total_service_hours, tip_per_hour
    }
}
}
</script>

<style scoped>
.finilize-shift{
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 800px;
    margin: auto;
}
.title{
    width: 100%;
    height: 80px;
    background: var(--secondary);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.244);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--yellow);
    font-size: 24px;
}
.shift-general-data{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 5px;
}
.shift-data{
    width: 100%;
    text-align: start;
    color: whitesmoke;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    font-size: 18px;
    margin: 5px 0 5px 0;
}
.workers-summery{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 5px;
}
.worker-line{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2.5px 0 2.5px 0;
    font-size: 18px;
    color: whitesmoke;
    background: var(--secondary);
    height: fit-content;
    padding: 5px 0 5px 0;
}
.worker-data{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 5px;
}
.total_time{
    margin: 5px 0 5px 0;
    color: var(--success);
    text-shadow: 0 0 3px black;
}
.add-note{
    position: absolute;
    top: 50px;
    width: 80px;
    left: 5px;
    background: var(--purple);
    color: white;
    padding: 10px 5px 10px 5px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.459);
}
.edit_worker{
    position: absolute;
    top: 5px;
    left: 5px;
    background: var(--purple);
    color: white;
    padding: 10px 5px 10px 5px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    width: 80px;
    text-align: center;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.459);
}
.tip-data{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 5px;
}
.money-data{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 5px;
}
.money-title{
    width: 100%;
    text-align: center;
    padding: 5px;
    background: var(--purple);
    color: white;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.534);
    font-size: 18px;
}
.flex-line{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0 5px 0;
    font-size: 18px;
    color: whitesmoke;
}
.flex-line :first-child{
    width:150px;
}
</style>